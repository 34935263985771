import React from 'react'
import { Link, useStaticQuery } from 'gatsby'
import Layout from '../../components/Layout'
import Img from "gatsby-image"
import LoadableFaceMask from '../../components/FaceMask/LoadableFaceMask'



class FaceMask extends React.Component {

    render() {

        return (
            <Layout title={"Face mask | Modelizame"} description={"desc"}>
                
                <section className="section margin-top-navbar" style={{minHeight: '80vh'}}>
                    {/* <div className="container"> */}
                        <LoadableFaceMask></LoadableFaceMask>

                        <div class="columns is-centered mt-40">
                            <div class="column is-8">

                                <div className="card" style={{borderRadius: '4px', width:"100%"}}>
                                    <div className="card-content">
                                        <p className="title is-4 has-text-centered">
                                            Seguimiento 3D utilizando deep learning
                                        </p>
                                        <p className="subtitle mt-20">
                                            Esta demo estima la posición 3D de 486 puntos faciales.
                                            Estos puntos se utilizan para colocar una mascarilla sobre la cara del participante.
                                            El modelo se ejecuta en tu navegador utilizando TensorFlow.js.
                                        </p>
                                    </div>
                                </div>
            
                                </div>

                            </div>

                        

                    {/* </div> */}
                  {/* </div> */}
                </section>

            </Layout>
        )
    }
}

export default FaceMask